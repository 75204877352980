import {CLEAR_TOKEN, SET_TOKEN} from "../types";

const INITIAL_STATE = {
    tokenId:null,
    profileObj:null
};

const loginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {...state, ...action.payload};
        case CLEAR_TOKEN:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default loginReducer;