import {SHOW_MSG, HIDE_MSG, CLEAR_STATE} from "../types";

const INITIAL_STATE = {
    active:false,
    message:''
};

const notificationReducer= (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_MSG:
            return {...state,...{active:true}, ...action.payload};
        case HIDE_MSG:
            return {...state,...{active:false,type:null,message:null}};
        case CLEAR_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default notificationReducer;