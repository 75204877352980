import call from "./call";
import {getAuthDataFromState} from "../utils";
import {SET_PKI_INFO} from "../types";

const getPKICertificatesReport= (reportValues) => async (dispatch,getState) =>
{
    const data=getAuthDataFromState(dispatch,getState);
    if(!data)
        return;
    const onResult= (result) => dispatch({type:SET_PKI_INFO,payload:result});
    dispatch(call("Service/getPKICertificatesReport", {...data,reportValues},onResult));
};
export default getPKICertificatesReport;