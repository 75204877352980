import {SET_TOKEN} from "../types";
import {getAuthDataFromGoogle} from "../utils";
import showScreen from "./showScreen";
import {REPORT_LIST_ROUTE} from "../components/Root/routes";

const login= (googleResponse) => async (dispatch) =>
{
    const data=getAuthDataFromGoogle(dispatch,googleResponse);
    if(!data)
        return;
    dispatch({type:SET_TOKEN,payload:data});
    dispatch(showScreen(REPORT_LIST_ROUTE));
};
export default login;