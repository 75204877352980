import {SET_CUSTOMERS} from "../types";

const INITIAL_STATE = {
    customerList:[]
};

const customerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CUSTOMERS:
            return {...state, ...action.payload};
        default:
            return state;
    }
};
export default customerReducer;