import React from "react";
import {connect} from "react-redux";
import {HIDE_MSG} from "../../types";
import {notification} from "antd";
import 'antd/lib/notification/style/css';

class Notification extends React.Component
{
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        let type=this.props.type;
        if(!type)
            type='error';
        if(!this.props.msg)
            return;
       notification[type]({
            message: this.props.msg,
            key: 'active',
            duration: type !== 'success' ? null : 10,
            onClose: () => {
                this.props.closeNotification();
                notification.close('active');
                this.setState({active: false, current: null});
            }
        });
    }

    constructor(props)
    {
        super(props);
        this.state={active:false};
    }

    render()
    {
        return <React.Fragment/>;

    }

}

const mapStateToProps = state => {
    return {
        language: state.language.language,
        active: state.notification.active,
        msg:state.notification.message,
        type:state.notification.type
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeNotification: () => {
            return dispatch({type:HIDE_MSG});
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);