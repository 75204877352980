import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import 'antd/lib/spin/style/css';
import '../../resources/css/Loader.css';
import { SettingTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';

export const Loading= ()  =>
{
    const antIcon = <SettingTwoTone twoToneColor="#1b8bf9" style={{ fontSize: 24}} spin />;
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div className="loading-container">
            <div id="circle">
                <Spin indicator={antIcon} style={{color:"blue"}} tip={'Cargando...'}/>
            </div>
        </div>;
};