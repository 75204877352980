import React from 'react';
import './App.css';
import 'antd/lib/form/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/card/style/css';
import 'antd/lib/layout/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/date-picker/style/css';
import {ConnectedRouter,routerMiddleware} from "connected-react-router";
import AppContainer from "./components/Root/AppContainer";
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import {createHashHistory} from 'history';

const history=createHashHistory({queryKey: false});
function configureStore(history) {
  const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
      reducers(history),
      composeEnhacers(applyMiddleware(reduxThunk, routerMiddleware(history)))
  );
}

function App() {
  return (
      <Provider store={configureStore(history)}>
        <ConnectedRouter history={history}>
                <AppContainer/>
        </ConnectedRouter>
      </Provider>
  );
}

export default App;