import React from 'react';
import {connect} from "react-redux";
import getCustomerList from "../../actions/getCustomerList";
import {Button, Form} from 'antd';
import MainLayout from "../Layout/Layout";
import {SearchOutlined} from "@ant-design/icons";
import { DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import showLogin from "../../actions/showLogin";
import getReport from "../../actions/getReport";
const { RangePicker } = DatePicker;

class AndesRequests extends React.Component
{
    componentDidMount()
    {
        if(!this.props.login.tokenId || !this.props.login.profileObj)
            this.props.showLogin();
    }

    render()
    {
        return <MainLayout title={'logo'} size={'medium'}>
            <Form labelCol={{span:8}} wrapperCol={{span:16}} style={{width:'100%'}} name="andesRequests"
                  onFinish={(formValues)=>this.props.getReport("getAndesReport","andes.csv",formValues)}>
                <Form.Item label="Seleccione el Periodo" name="range"
                           rules={[{ required: true, message: 'Seleccione la fecha inicial y final' }]}>
                    <RangePicker locale={locale} style={{width:'100%'}}/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8,span:16}}>
                    <Button style={{width:'100%',maxWidth:'100%'}} htmlType={'submit'} type="primary" shape="round"
                            icon={<SearchOutlined />} size={'large'}>Consultar</Button>
                </Form.Item>
            </Form>
        </MainLayout>;
    }
}

function mapStateToProps(state) {
    return {
        login: state.login
    }
}
export default connect(mapStateToProps, {getCustomerList,showLogin,getReport})(AndesRequests);