import {CLEAR_REPORT_LIST, UPDATE_REPORTS} from "../types";

const INITIAL_STATE = {
    reportList:[]
};

const reportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_REPORTS:
            return {...state, ...action.payload};
        case CLEAR_REPORT_LIST:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default reportReducer;