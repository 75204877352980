import React from 'react';
import {Button, Col, Form, Input, Row, DatePicker, Select, InputNumber} from "antd";
import language from "../../languages/language";
import Layout from "../Layout/Layout";
import {connect} from "react-redux";
import fillData from "../../actions/fillData";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {LockOutlined} from "@ant-design/icons";
import 'antd/lib/input-number/style/css';

const { Option } = Select

class FillData extends React.Component
{
    render()
    {
        const dateFormat = 'DD/MM/YYYY';
        return <Layout title={'App para registro de usuarios Netco Demo One Drive'} size={'medium'}>
            <Form initialValues={{codcelular:'57'}} style={{width:'100%'}} onFinish={this.props.fillData} className={'regularForm'}>
                <Row style={{marginBottom: 20}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        Contraseña de la aplicación
                        <Form.Item name="password"
                                   rules={[{required: true,
                                       message: language[this.props.language].requiredError }]}>
                            <Input.Password placeholder={'Contraseña de la aplicación'}
                                   size={'large'} className={'mb-10'}
                                   prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="nit"
                                   rules={[{required: true,
                                       message: language[this.props.language].requiredError }]}>
                            <Input placeholder={'Cédula'}
                                   size={'large'} className={'mb-10'}
                                   prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="nombres"
                                   rules={[{required: true,
                                       message: language[this.props.language].requiredError }]}>
                            <Input placeholder={'Nombres'}
                                   size={'large'} className={'mb-10'}
                                   prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="mail"
                                   rules={[{required: true,type: "email",
                                       message: 'Correo inválido' }]}>
                            <Input placeholder={'Correo electrónico'}
                                   size={'large'} className={'mb-10'}
                                   prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={10}>
                        <Form.Item name="codcelular"
                                   rules={[{required: true,
                                       message: language[this.props.language].requiredError }]}>
                            <Select placeholder={'Seleccione el País'}
                                   size={'large'} className={'mb-10'}>
                                <Select.Option value="93">(+93) - Afghanistan</Select.Option>
                                <Select.Option value="355">(+355) - Albania</Select.Option>
                                <Select.Option value="1403">(+1403) - Alberta</Select.Option>
                                <Select.Option value="1780">(+1780) - Alberta</Select.Option>
                                <Select.Option value="213">(+213) - Algeria</Select.Option>
                                <Select.Option value="376">(+376) - Andorra</Select.Option>
                                <Select.Option value="244">(+244) - Angola</Select.Option>
                                <Select.Option value="1264">(+1264) - Anguilla</Select.Option>
                                <Select.Option value="6721">(+6721) - Antarctica (Australian bases)</Select.Option>
                                <Select.Option value="1268">(+1268) - Antigua and Barbuda</Select.Option>
                                <Select.Option value="54">(+54) - Argentina</Select.Option>
                                <Select.Option value="374">(+374) - Armenia</Select.Option>
                                <Select.Option value="297">(+297) - Aruba</Select.Option>
                                <Select.Option value="247">(+247) - Ascension</Select.Option>
                                <Select.Option value="61">(+61) - Australia</Select.Option>
                                <Select.Option value="43">(+43) - Austria</Select.Option>
                                <Select.Option value="994">(+994) - Azerbaijan</Select.Option>
                                <Select.Option value="1242">(+1242) - Bahamas</Select.Option>
                                <Select.Option value="973">(+973) - Bahrain</Select.Option>
                                <Select.Option value="880">(+880) - Bangladesh</Select.Option>
                                <Select.Option value="1246">(+1246) - Barbados</Select.Option>
                                <Select.Option value="375">(+375) - Belarus</Select.Option>
                                <Select.Option value="32">(+32) - Belgium</Select.Option>
                                <Select.Option value="501">(+501) - Belize</Select.Option>
                                <Select.Option value="229">(+229) - Benin</Select.Option>
                                <Select.Option value="1441">(+1441) - Bermuda</Select.Option>
                                <Select.Option value="975">(+975) - Bhutan</Select.Option>
                                <Select.Option value="591">(+591) - Bolivia</Select.Option>
                                <Select.Option value="387">(+387) - Bosnia and Herzegovina</Select.Option>
                                <Select.Option value="267">(+267) - Botswana</Select.Option>
                                <Select.Option value="55">(+55) - Brazil</Select.Option>
                                <Select.Option value="1250">(+1250) - British Columbia</Select.Option>
                                <Select.Option value="1604">(+1604) - British Columbia</Select.Option>
                                <Select.Option value="1778">(+1778) - British Columbia</Select.Option>
                                <Select.Option value="246">(+246) - British Indian Ocean Territory</Select.Option>
                                <Select.Option value="1284">(+1284) - British Virgin Islands</Select.Option>
                                <Select.Option value="673">(+673) - Brunei</Select.Option>
                                <Select.Option value="359">(+359) - Bulgaria</Select.Option>
                                <Select.Option value="226">(+226) - Burkina Faso</Select.Option>
                                <Select.Option value="257">(+257) - Burundi</Select.Option>
                                <Select.Option value="855">(+855) - Cambodia</Select.Option>
                                <Select.Option value="237">(+237) - Cameroon</Select.Option>
                                <Select.Option value="238">(+238) - Cape Verde</Select.Option>
                                <Select.Option value="1345">(+1345) - Cayman Islands</Select.Option>
                                <Select.Option value="236">(+236) - Central African Republic</Select.Option>
                                <Select.Option value="235">(+235) - Chad</Select.Option>
                                <Select.Option value="56">(+56) - Chile</Select.Option>
                                <Select.Option value="86">(+86) - China</Select.Option>
                                <Select.Option value="57">(+57) - Colombia</Select.Option>
                                <Select.Option value="269">(+269) - Comoros</Select.Option>
                                <Select.Option value="243">(+243) - Democratic Republic of the Congo</Select.Option>
                                <Select.Option value="242">(+242) - Republic of the Congo</Select.Option>
                                <Select.Option value="682">(+682) - Cook Islands</Select.Option>
                                <Select.Option value="506">(+506) - Costa Rica</Select.Option>
                                <Select.Option value="712">(+712) - Cote dIvoire</Select.Option>
                                <Select.Option value="385">(+385) - Croatia</Select.Option>
                                <Select.Option value="53">(+53) - Cuba</Select.Option>
                                <Select.Option value="357">(+357) - Cyprus</Select.Option>
                                <Select.Option value="420">(+420) - Czech Republic</Select.Option>
                                <Select.Option value="45">(+45) - Denmark</Select.Option>
                                <Select.Option value="253">(+253) - Djibouti</Select.Option>
                                <Select.Option value="1767">(+1767) - Dominica</Select.Option>
                                <Select.Option value="1809">(+1809) - Dominican Republic</Select.Option>
                                <Select.Option value="1829">(+1829) - Dominican Republic</Select.Option>
                                <Select.Option value="670">(+670) - East Timor</Select.Option>
                                <Select.Option value="593">(+593) - Ecuador</Select.Option>
                                <Select.Option value="20">(+20) - Egypt</Select.Option>
                                <Select.Option value="503">(+503) - El Salvador</Select.Option>
                                <Select.Option value="240">(+240) - Equatorial Guinea</Select.Option>
                                <Select.Option value="291">(+291) - Eritrea</Select.Option>
                                <Select.Option value="34">(+34) - España</Select.Option>
                                <Select.Option value="372">(+372) - Estonia</Select.Option>
                                <Select.Option value="251">(+251) - Ethiopia</Select.Option>
                                <Select.Option value="500">(+500) - Falkland Islands</Select.Option>
                                <Select.Option value="298">(+298) - Faroe Islands</Select.Option>
                                <Select.Option value="679">(+679) - Fiji</Select.Option>
                                <Select.Option value="358">(+358) - Finland</Select.Option>
                                <Select.Option value="33">(+33) - France</Select.Option>
                                <Select.Option value="594">(+594) - French Guiana</Select.Option>
                                <Select.Option value="689">(+689) - French Polynesia</Select.Option>
                                <Select.Option value="241">(+241) - Gabon</Select.Option>
                                <Select.Option value="220">(+220) - Gambia</Select.Option>
                                <Select.Option value="995">(+995) - Georgia</Select.Option>
                                <Select.Option value="49">(+49) - Germany</Select.Option>
                                <Select.Option value="233">(+233) - Ghana</Select.Option>
                                <Select.Option value="350">(+350) - Gibraltar</Select.Option>
                                <Select.Option value="30">(+30) - Greece</Select.Option>
                                <Select.Option value="299">(+299) - Greenland</Select.Option>
                                <Select.Option value="1473">(+1473) - Grenada</Select.Option>
                                <Select.Option value="1671">(+1671) - Guam</Select.Option>
                                <Select.Option value="502">(+502) - Guatemala</Select.Option>
                                <Select.Option value="224">(+224) - Guinea</Select.Option>
                                <Select.Option value="245">(+245) - Guinea-Bissau</Select.Option>
                                <Select.Option value="592">(+592) - Guyana</Select.Option>
                                <Select.Option value="509">(+509) - Haiti</Select.Option>
                                <Select.Option value="504">(+504) - Honduras</Select.Option>
                                <Select.Option value="852">(+852) - Hong Kong</Select.Option>
                                <Select.Option value="36">(+36) - Hungary</Select.Option>
                                <Select.Option value="354">(+354) - Iceland</Select.Option>
                                <Select.Option value="91">(+91) - India</Select.Option>
                                <Select.Option value="62">(+62) - Indonesia</Select.Option>
                                <Select.Option value="964">(+964) - Iraq</Select.Option>
                                <Select.Option value="98">(+98) - Iran</Select.Option>
                                <Select.Option value="353">(+353) - Ireland (Eire)</Select.Option>
                                <Select.Option value="972">(+972) - Israel</Select.Option>
                                <Select.Option value="39">(+39) - Italy</Select.Option>
                                <Select.Option value="1876">(+1876) - Jamaica</Select.Option>
                                <Select.Option value="81">(+81) - Japan</Select.Option>
                                <Select.Option value="962">(+962) - Jordan</Select.Option>
                                <Select.Option value="254">(+254) - Kenya</Select.Option>
                                <Select.Option value="686">(+686) - Kiribati</Select.Option>
                                <Select.Option value="965">(+965) - Kuwait</Select.Option>
                                <Select.Option value="996">(+996) - Kyrgyzstan</Select.Option>
                                <Select.Option value="856">(+856) - Laos</Select.Option>
                                <Select.Option value="371">(+371) - Latvia</Select.Option>
                                <Select.Option value="961">(+961) - Lebanon</Select.Option>
                                <Select.Option value="266">(+266) - Lesotho</Select.Option>
                                <Select.Option value="231">(+231) - Liberia</Select.Option>
                                <Select.Option value="218">(+218) - Libya</Select.Option>
                                <Select.Option value="423">(+423) - Liechtenstein</Select.Option>
                                <Select.Option value="370">(+370) - Lithuania</Select.Option>
                                <Select.Option value="352">(+352) - Luxembourg</Select.Option>
                                <Select.Option value="853">(+853) - Macau</Select.Option>
                                <Select.Option value="389">(+389) - Republic of Macedonia</Select.Option>
                                <Select.Option value="261">(+261) - Madagascar</Select.Option>
                                <Select.Option value="265">(+265) - Malawi</Select.Option>
                                <Select.Option value="60">(+60) - Malaysia</Select.Option>
                                <Select.Option value="960">(+960) - Maldives</Select.Option>
                                <Select.Option value="223">(+223) - Mali</Select.Option>
                                <Select.Option value="356">(+356) - Malta</Select.Option>
                                <Select.Option value="1204">(+1204) - Manitoba</Select.Option>
                                <Select.Option value="692">(+692) - Marshall Islands</Select.Option>
                                <Select.Option value="596">(+596) - Martinique</Select.Option>
                                <Select.Option value="222">(+222) - Mauritania</Select.Option>
                                <Select.Option value="230">(+230) - Mauritius</Select.Option>
                                <Select.Option value="52">(+52) - Mexico</Select.Option>
                                <Select.Option value="691">(+691) - Federated States of Micronesia</Select.Option>
                                <Select.Option value="373">(+373) - Moldova</Select.Option>
                                <Select.Option value="377">(+377) - Monaco</Select.Option>
                                <Select.Option value="976">(+976) - Mongolia</Select.Option>
                                <Select.Option value="382">(+382) - Montenegro</Select.Option>
                                <Select.Option value="1664">(+1664) - Montserrat</Select.Option>
                                <Select.Option value="212">(+212) - Morocco</Select.Option>
                                <Select.Option value="258">(+258) - Mozambique</Select.Option>
                                <Select.Option value="95">(+95) - Myanmar</Select.Option>
                                <Select.Option value="264">(+264) - Namibia</Select.Option>
                                <Select.Option value="674">(+674) - Nauru</Select.Option>
                                <Select.Option value="31">(+31) - Netherlands</Select.Option>
                                <Select.Option value="599">(+599) - Netherlands Antilles</Select.Option>
                                <Select.Option value="977">(+977) - Nepal</Select.Option>
                                <Select.Option value="1506">(+1506) - New Brunswick</Select.Option>
                                <Select.Option value="687">(+687) - New Caledonia</Select.Option>
                                <Select.Option value="64">(+64) - New Zealand</Select.Option>
                                <Select.Option value="1709">(+1709) - Newfoundland</Select.Option>
                                <Select.Option value="505">(+505) - Nicaragua</Select.Option>
                                <Select.Option value="227">(+227) - Niger</Select.Option>
                                <Select.Option value="234">(+234) - Nigeria</Select.Option>
                                <Select.Option value="683">(+683) - Niue</Select.Option>
                                <Select.Option value="6723">(+6723) - Norfolk Island</Select.Option>
                                <Select.Option value="850">(+850) - North Korea</Select.Option>
                                <Select.Option value="1670">(+1670) - Northern Mariana Islands</Select.Option>
                                <Select.Option value="1867">(+1867) - Northwest Territories</Select.Option>
                                <Select.Option value="47">(+47) - Norway</Select.Option>
                                <Select.Option value="1902">(+1902) - Nova Scotia</Select.Option>
                                <Select.Option value="968">(+968) - Oman</Select.Option>
                                <Select.Option value="1416">(+1416) - Ontario</Select.Option>
                                <Select.Option value="1519">(+1519) - Ontario</Select.Option>
                                <Select.Option value="1613">(+1613) - Ontario</Select.Option>
                                <Select.Option value="1647">(+1647) - Ontario</Select.Option>
                                <Select.Option value="1705">(+1705) - Ontario</Select.Option>
                                <Select.Option value="1807">(+1807) - Ontario</Select.Option>
                                <Select.Option value="1905">(+1905) - Ontario</Select.Option>
                                <Select.Option value="92">(+92) - Pakistan</Select.Option>
                                <Select.Option value="680">(+680) - Palau</Select.Option>
                                <Select.Option value="507">(+507) - Panama</Select.Option>
                                <Select.Option value="675">(+675) - Papua New Guinea</Select.Option>
                                <Select.Option value="595">(+595) - Paraguay</Select.Option>
                                <Select.Option value="51">(+51) - Peru</Select.Option>
                                <Select.Option value="63">(+63) - Philippines</Select.Option>
                                <Select.Option value="48">(+48) - Poland</Select.Option>
                                <Select.Option value="351">(+351) - Portugal</Select.Option>
                                <Select.Option value="974">(+974) - Qatar</Select.Option>
                                <Select.Option value="1418">(+1418) - Quebec</Select.Option>
                                <Select.Option value="1450">(+1450) - Quebec</Select.Option>
                                <Select.Option value="1514">(+1514) - Quebec</Select.Option>
                                <Select.Option value="1819">(+1819) - Quebec</Select.Option>
                                <Select.Option value="262">(+262) - Reunion</Select.Option>
                                <Select.Option value="40">(+40) - Romania</Select.Option>
                                <Select.Option value="7">(+7) - Russia</Select.Option>
                                <Select.Option value="250">(+250) - Rwanda</Select.Option>
                                <Select.Option value="290">(+290) - Saint Helena</Select.Option>
                                <Select.Option value="1869">(+1869) - Saint Kitts and Nevis</Select.Option>
                                <Select.Option value="1758">(+1758) - Saint Lucia</Select.Option>
                                <Select.Option value="590">(+590) - Saint Martin (French side)</Select.Option>
                                <Select.Option value="508">(+508) - Saint Pierre and Miquelon</Select.Option>
                                <Select.Option value="685">(+685) - Samoa</Select.Option>
                                <Select.Option value="239">(+239) - Sao Tome and Principe</Select.Option>
                                <Select.Option value="1306">(+1306) - Saskatchewan</Select.Option>
                                <Select.Option value="966">(+966) - Saudi Arabia</Select.Option>
                                <Select.Option value="221">(+221) - Senegal</Select.Option>
                                <Select.Option value="381">(+381) - Serbia</Select.Option>
                                <Select.Option value="248">(+248) - Seychelles</Select.Option>
                                <Select.Option value="232">(+232) - Sierra Leone</Select.Option>
                                <Select.Option value="65">(+65) - Singapore</Select.Option>
                                <Select.Option value="421">(+421) - Slovakia</Select.Option>
                                <Select.Option value="386">(+386) - Slovenia</Select.Option>
                                <Select.Option value="677">(+677) - Solomon Islands</Select.Option>
                                <Select.Option value="252">(+252) - Somalia</Select.Option>
                                <Select.Option value="27">(+27) - South Africa</Select.Option>
                                <Select.Option value="82">(+82) - South Korea</Select.Option>
                                <Select.Option value="211">(+211) - South Sudan</Select.Option>
                                <Select.Option value="94">(+94) - Sri Lanka</Select.Option>
                                <Select.Option value="249">(+249) - Sudan</Select.Option>
                                <Select.Option value="597">(+597) - Suriname</Select.Option>
                                <Select.Option value="268">(+268) - Swaziland</Select.Option>
                                <Select.Option value="46">(+46) - Sweden</Select.Option>
                                <Select.Option value="41">(+41) - Switzerland</Select.Option>
                                <Select.Option value="963">(+963) - Syria</Select.Option>
                                <Select.Option value="886">(+886) - Taiwan</Select.Option>
                                <Select.Option value="992">(+992) - Tajikistan</Select.Option>
                                <Select.Option value="255">(+255) - Tanzania</Select.Option>
                                <Select.Option value="66">(+66) - Thailand</Select.Option>
                                <Select.Option value="228">(+228) - Togo</Select.Option>
                                <Select.Option value="690">(+690) - Tokelau</Select.Option>
                                <Select.Option value="676">(+676) - Tonga</Select.Option>
                                <Select.Option value="1868">(+1868) - Trinidad and Tobago</Select.Option>
                                <Select.Option value="216">(+216) - Tunisia</Select.Option>
                                <Select.Option value="90">(+90) - Turkey</Select.Option>
                                <Select.Option value="993">(+993) - Turkmenistan</Select.Option>
                                <Select.Option value="1649">(+1649) - Turks and Caicos Islands</Select.Option>
                                <Select.Option value="688">(+688) - Tuvalu</Select.Option>
                                <Select.Option value="256">(+256) - Uganda</Select.Option>
                                <Select.Option value="380">(+380) - Ukraine</Select.Option>
                                <Select.Option value="971">(+971) - United Arab Emirates</Select.Option>
                                <Select.Option value="44">(+44) - United Kingdom</Select.Option>
                                <Select.Option value="1">(+1) - United States of America</Select.Option>
                                <Select.Option value="598">(+598) - Uruguay</Select.Option>
                                <Select.Option value="998">(+998) - Uzbekistan</Select.Option>
                                <Select.Option value="678">(+678) - Vanuatu</Select.Option>
                                <Select.Option value="58">(+58) - Venezuela</Select.Option>
                                <Select.Option value="84">(+84) - Vietnam</Select.Option>
                                <Select.Option value="1340">(+1340) - U.S. Virgin Islands</Select.Option>
                                <Select.Option value="681">(+681) - Wallis and Futuna</Select.Option>
                                <Select.Option value="970">(+970) - West Bank</Select.Option>
                                <Select.Option value="967">(+967) - Yemen</Select.Option>
                                <Select.Option value="260">(+260) - Zambia</Select.Option>
                                <Select.Option value="263">(+263) - Zimbabwe</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="celular"
                                   rules={[{required: true,
                                       message: language[this.props.language].requiredError}]}>
                            <InputNumber  placeholder={'Celular'} style={{marginLeft:'10px', height:'41px', width:'90%'}}
                                   size={'large'} className={'mb-10'} controls={false}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="direccion">
                            <Input placeholder={'Dirección de vivienda'}
                                   size={'large'} className={'mb-10'}
                                   prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="fecha_cumple_ter">
                            <DatePicker className={'mb-10'} style={{width:'100%',height:'38px'}} placeholder={'Fecha de nacimiento'}
                                 format={dateFormat} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="tipo_identificacion">
                            <Select className={'mb-10'} placeholder={'Tipo de documento'} style={{ width: '100%' }}>
                                <Option value="CC">Cédula de ciudadanía</Option>
                                <Option value="NIT">NIT</Option>
                                <Option value="Pasaporte">Pasaporte</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="sexo">
                            <Select className={'mb-10'} placeholder={'Genero'} style={{ width: '100%' }}>
                                <Option value="M">Masculino</Option>
                                <Option value="F">Femenino</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                    <Col span={16}>
                        <Form.Item name="ciudad">
                            <Select className={'mb-10'} placeholder={'Ciudad'} style={{ width: '100%' }}>
                                <Option value="BOGOTA">BOGOTA</Option>
                                <Option value="MEDELLIN">MEDELLIN</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
                <Row justify={'center'} style={{width:'100%'}}>
                <Col span={16}>
                    <Form.Item className={'text-center'}>
                        <Button style={{width:'100%'}} type="primary light-blue rounded" htmlType={'submit'}>
                            {'Crear'}
                        </Button>
                    </Form.Item>
                </Col>
                </Row>
                <Row style={{marginBottom: 10}}/>
        </Form>
        </Layout>
    }
}
function mapStateToProps(state) {
    return {
        language: state.language.language
    }
}
export default connect(mapStateToProps,{fillData})(FillData);