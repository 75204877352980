import call from "./call";
import {UPDATE_REPORTS} from "../types";
import {getAuthDataFromState} from "../utils";


const getReportList= () => async (dispatch,getState) =>
{
    const data=getAuthDataFromState(dispatch,getState);
    if(!data)
        return;
    const onResult= (result) => dispatch({type:UPDATE_REPORTS,payload:result});
    dispatch(call("Service/getReportList", data,onResult));
};
export default getReportList;