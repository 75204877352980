import React from 'react';
import {Card, Col, Layout, Row} from "antd";
import logo from "../../resources/img/logo.png";
import {clientID} from "../../url";
import {GoogleLogout} from "react-google-login";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import showLogin from "../../actions/showLogin";
import {REPORT_LIST_ROUTE} from "../Root/routes";
import {Link} from "react-router-dom";

const MainLayout = (props) => {

    const renderImg = () => {
        return (
            <img alt={''} src={logo}/>
        );
    };

    const getSize = (size) => {
        switch (size) {
            case 'sm':
                return props.size === 'large' ? 18 : 14;
            case 'md':
                return props.size === 'large' ? 16 : 10;
            case 'xl':
                return props.size === 'large' ? 14 : 8;
            default:
                break;
        }
    };

    return (
        <Layout className="h-100 public-layout">
            <Layout.Content>
                <Row type="flex" justify="space-around" align="middle" className={'h-100'}>
                    <Col xs={24} sm={getSize('sm')} md={getSize('md')} xl={getSize('xl')}>
                        <Card bodyStyle={props.bodyStyle?props.bodyStyle:{}} className={'public-card'} title={props.title === 'logo' ? renderImg() : props.title}
                              headStyle={{textAlign: 'center'}}>
                            <Row justify={'center'} className={'w-100'}>
                                {props.children}
                            </Row>
                            {props.login.tokenId && props.login.profileObj?
                                <>
                                    <Row justify={'center'} style={{marginTop:'20px'}} className={'w-100'}>
                                        <Link to={REPORT_LIST_ROUTE}>Volver al Menú Principal</Link>
                                    </Row>
                                    <Row justify={'center'} className={'w-100'}>

                                    <GoogleLogout clientId={clientID} buttonText="Cerrar Sesión"
                                                  onLogoutSuccess={()=>props.showLogin(false)}>
                                    </GoogleLogout>
                                </Row></>:''}
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};
function mapStateToProps(state) {
    return {
        login: state.login
    }
}
export default withRouter(connect(mapStateToProps, {showLogin})(MainLayout));
