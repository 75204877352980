export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SHOW_MSG= 'SHOW_MSG';
export const HIDE_MSG='HIDE_MSG';
export const CLEAR_STATE='CLEAR_STATE';
export const HIDE_MODAL='HIDE_MODAL';
export const SHOW_MODAL='SHOW_MODAL';
export const UPDATE_REPORTS='UPDATE_REPORTS';
export const SET_TOKEN='SET_TOKEN';
export const CLEAR_TOKEN='CLEAR_TOKEN';
export const SET_CUSTOMERS='SET_CUSTOMERS';
export const SET_PKI_INFO='SET_PKI_INFO';
export const CLEAR_PKI_INFO='CLEAR_PKI_INFO';
export const CLEAR_REPORT_LIST='CLEAR_REPORT_LIST';