import React from 'react';
import {connect} from 'react-redux'
import modalDismiss from '../../actions/modalDismiss';



const MODAL_COMPONENTS = {

};

const Modal = (props) => {

    if (!props.modal || !props.modal.modalType) {
        return null
    }

    const dismissModal = () => {
        props.modalDismiss();
    };

    const SpecificModal = MODAL_COMPONENTS[props.modal.modalType];
    const modalProps = {
        onOk: dismissModal,
        onCancel: dismissModal,
        maskClosable: true,
        ...props.modal.modalProps,
        className: 'text-center'
    };
    return <SpecificModal modalProps={modalProps} {...props.modal.componentProps} />;

};

function mapStateToProps(state) {
    return {
        modal: state.modal
    }
}

export default (connect(mapStateToProps, {modalDismiss})(Modal));