import call from './call';
import messageShow from "./messageShow";

const fillData= (formValues) => async (dispatch,getState) =>
{
    if(!formValues.password || formValues.password==='')
    {
        dispatch(messageShow({type:'error',message:'El campo contraseña de la aplicación es requerido'}));
        return;
    }
    formValues.celular='+' + formValues.codcelular + '-' + formValues.celular;
    delete formValues.codcelular;
    //NO auth for this report
    /*const data=getAuthDataFromState(dispatch,getState);
    if(!data)
        return;*/
    if(formValues.fecha_cumple_ter)
        formValues.fecha_cumple_ter=formValues.fecha_cumple_ter.format('DD/MM/YYYY');
    const onResult= (result) => dispatch(messageShow({message: "Usuario creado/actualizado correctamente con orden: " + result.newOrderID , type: 'success'}));
    dispatch(call("Service/fillDemoOneDriveData",{formValues},onResult));
};
export default fillData;