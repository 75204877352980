import {trackPromise} from "react-promise-tracker";
import {HIDE_MSG, SHOW_MSG} from "../types";
import {URL} from '../url';
import showLogin from "./showLogin";

function customFetch(serviceName,jsonValues,callback,dispatch)
{
    return customFetchURL(URL,serviceName,jsonValues,callback,dispatch);
}

function customFetchURL(url,serviceName,jsonValues,callback,dispatch)
{
    return fetch(url +serviceName, {
        method: 'POST',
        headers: {Accept: 'application/json','Content-Type': 'application/json'},
        body: JSON.stringify(jsonValues),
        credentials: 'include'
    }).then(Response => {
        if(Response.status !== 200)
        {
            let message=Response.status;
            console.error("HTTP status error:",message);
            if(Response.status===404)
                message="Error, No se encontró el servidor";
            dispatch({type:SHOW_MSG,payload: {message:message,type:'error'}});
            return;
        }
        return Promise.all([Response, Response.json()]).catch(reason => {
            console.log("error",reason);
            dispatch({type:SHOW_MSG,payload: {message:reason.message,type:'error'}});
        })
    }).catch(reason => {
        console.log(reason.message);
        dispatch({type:SHOW_MSG,payload: {message:'Error de servidor',type:'error'}});
    });
}

const call= (serviceName,jsonValues,callback,track=true) => async (dispatch) =>
{
    let toTrack=customFetch(serviceName,jsonValues,callback,dispatch);
    if(track)
        toTrack=trackPromise(toTrack);
    toTrack.then(result => {
            if(result && result[1])
            {
                if(result[1].success)
                {
                    dispatch({type: HIDE_MSG});
                    if(callback)
                        callback(result[1]);
                }
                else
                {
                    const error=result[1].error;
                    if(!error)
                    {
                        dispatch({type: SHOW_MSG, payload: {message: 'Error desconocido (no error)',type:'error'}});
                        return;
                    }
                    if(error==="invalidSession")
                    {
                        dispatch({type: SHOW_MSG, payload: {message: "Sesión inválida",type:'error'}});
                        dispatch(showLogin(false));
                    }
                    else
                        dispatch({type: SHOW_MSG, payload: {message: error,type:'error'}});
                }
            }
        }
    ).catch(reason => {
        dispatch({type:SHOW_MSG,payload: {message:reason.message,type:'error'}});
    });
};
export default call;
