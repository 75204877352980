import {CLEAR_PKI_INFO, SET_PKI_INFO} from "../types";

const INITIAL_STATE = {
};

const pkiReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PKI_INFO:
            return {...state, ...action.payload};
        case CLEAR_PKI_INFO:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default pkiReducer;