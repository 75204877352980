import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import getReportList from "../../actions/getReportList";
import showLogin from "../../actions/showLogin";
import MainLayout from "../Layout/Layout";
import {Row,Typography} from "antd";

class ReportsList extends React.Component
{
    componentDidMount()
    {
        if(!this.props.login.tokenId || !this.props.login.profileObj)
            this.props.showLogin();
        if(this.props.reports.reportList.length==0)
            this.props.getReportList();
    }

    render()
    {
        return (
            <MainLayout title={'logo'} size={'medium'}>
            <Row justify={'center'} className={'w-100'}>
            <Typography style={{fontWeight:'bold',marginBottom:'20px'}}>Opciones Disponibles</Typography>
            </Row>
            {this.props.reports.reportList.length>0?
                this.props.reports.reportList.map(report=>
                    <Row justify={'center'} className={'w-100'}><Link to={"/" + report.link}>{report.name}</Link>
                    </Row>):''}
        </MainLayout>);
    }
}
function mapStateToProps(state) {
    return {
        login: state.login,
        reports: state.reports
    }
}
export default connect(mapStateToProps, {showLogin,getReportList})(ReportsList);