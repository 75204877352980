import call from "./call";
import {getAuthDataFromState, handleBase64Response} from "../utils";

const getReport= (reportName, fileName,reportValues) => async (dispatch,getState) =>
{
    const data=getAuthDataFromState(dispatch,getState);
    if(!data)
        return;
    const onResult= (result) => handleBase64Response(result.report,fileName);
    dispatch(call("Service/" + reportName, {...data,reportValues},onResult));
};
export default getReport;