import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import languageReducer from "./languageReducer";
import notificationReducer from "./notificationReducer";
import reportReducer from "./reportReducer";
import loginReducer from "./loginReducer";
import customerReducer from "./customerReducer";
import pkiReducer from "./pkiReducer";
const reducers= (history) => combineReducers({
    router: connectRouter(history),
    language: languageReducer,
    notification:notificationReducer,
    reports:reportReducer,
    login:loginReducer,
    customer:customerReducer,
    pki:pkiReducer

});
export default reducers;