import React from 'react';
import {connect} from "react-redux";
import GoogleLogin from 'react-google-login';
import login from "../../actions/login";
import {clientID} from "../../url";
import MainLayout from "../Layout/Layout";
import {Row} from "antd";
class Login extends React.Component
{
    render() {
        return (
            <MainLayout title={'logo'} size={'medium'}>
                <Row justify={'center'} style={{width:'100%'}}>
                    <GoogleLogin clientId={clientID} buttonText="Iniciar sesión usando una cuenta de Google"

                                 onSuccess={this.props.login} onFailure={this.props.login}
                                 cookiePolicy={'single_host_origin'}
                    />
                </Row>
            </MainLayout>
        );
    }
}
export default connect(null, {login})(Login);