import call from "./call";
import {SET_CUSTOMERS} from "../types";
import {getAuthDataFromState} from "../utils";

const getCustomerList= (service=2) => async (dispatch,getState) =>
{
    const data=getAuthDataFromState(dispatch,getState);
    if(!data)
        return;
    data.service=service;
    const onResult= (result) => dispatch({type:SET_CUSTOMERS,payload:result});
    dispatch(call("Service/getCustomerList", data,onResult));
};
export default getCustomerList;