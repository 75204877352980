import messageShow from "./messageShow";
import {LOGIN_ROUTE} from "../components/Root/routes";
import showScreen from "./showScreen";
import {CLEAR_PKI_INFO, CLEAR_REPORT_LIST, CLEAR_TOKEN} from "../types";

const showLogin= (showError=true) => async (dispatch) =>
{
    if(showError)
        dispatch(messageShow({type:'error',message:'No autenticado'}));
    dispatch({type:CLEAR_TOKEN});
    dispatch({type:CLEAR_REPORT_LIST});
    dispatch({type:CLEAR_PKI_INFO});
    dispatch(showScreen(LOGIN_ROUTE));
};
export default showLogin;