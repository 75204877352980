import showLogin from "./actions/showLogin";
import messageShow from "./actions/messageShow";

export const getAuthDataFromState=(dispatch,getState)=>
{
    const {tokenId,profileObj}=getState().login;
    return getAuthData(dispatch,{tokenId,profileObj});
};

export const getAuthDataFromGoogle=(dispatch,googleResponse)=>
{
    if(googleResponse && googleResponse.error && googleResponse.details)
    {
        dispatch(messageShow({type:'error',message:googleResponse.details}));
        dispatch(showLogin(false));
        return;
    }
    const {tokenId,profileObj}=googleResponse;
    return getAuthData(dispatch,{tokenId,profileObj});

};
export const handleBase64Response=(base64File,fileName)=>
{
    let binaryString =  window.atob(base64File);
    let binaryLen = binaryString.length;
    let ab = new ArrayBuffer(binaryLen);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < binaryLen; i++) {
        ia[i] = binaryString.charCodeAt(i);
    }
    let blob = new Blob([ab]);
    let csv = window.document.createElement("a");
    csv.href = window.URL.createObjectURL(blob);
    csv.download = fileName;
    document.body.appendChild(csv);
    csv.click();
    document.body.removeChild(csv);
};
const getAuthData=(dispatch,data)=>
{
    if(!data || !data.tokenId || !data.profileObj)
    {
        dispatch(showLogin());
        return null;
    }
    return data;
};