import React from 'react';
import {connect} from "react-redux";
import getCustomerList from "../../actions/getCustomerList";
import {Button, Select,Form} from 'antd';
import MainLayout from "../Layout/Layout";
import {SearchOutlined} from "@ant-design/icons";
import { DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import showLogin from "../../actions/showLogin";
import getReport from "../../actions/getReport";


const { RangePicker } = DatePicker;
const { Option } = Select;

class SignerOperations extends React.Component
{
    componentDidMount()
    {
        if(!this.props.login.tokenId || !this.props.login.profileObj)
            this.props.showLogin();
        this.props.getCustomerList();
    }

    render()
    {
        return <MainLayout title={'logo'} size={'medium'}>
            <Form labelCol={{span:8}} wrapperCol={{span:16}} style={{width:'100%'}} name="operations"
                  onFinish={(formValues)=>this.props.getReport("getOperationsReport","reporte.csv",formValues)}>
                <Form.Item label="Seleccione el cliente" name="customer"
                           rules={[{ required: true, message: 'Seleccione un cliente' }]}>

                        <Select placeholder={'Seleccione el cliente'} style={{width:'100%'}}>
                            {this.props.customerList.map(customer=><Option value={customer.id}>{customer.name}</Option>)}
                        </Select>
                </Form.Item>
                <Form.Item label="Seleccione el Periodo" name="range"
                           rules={[{ required: true, message: 'Seleccione la fecha inicial y final' }]}>
                    <RangePicker locale={locale} style={{width:'100%'}}/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 8,span:16}}>
                    <Button style={{width:'100%',maxWidth:'100%'}} htmlType={'submit'} type="primary" shape="round"
                            icon={<SearchOutlined />} size={'large'}>Consultar</Button>
                </Form.Item>
            </Form>
        </MainLayout>;
    }
}

function mapStateToProps(state) {
    return {
        login: state.login,
        customerList:state.customer.customerList
    }
}
export default connect(mapStateToProps, {getCustomerList,showLogin,getReport})(SignerOperations);