import React from 'react';
import {connect} from "react-redux";
import {Layout} from "antd";
import {Switch,Route } from 'react-router-dom';
import { withRouter } from "react-router";
import {Loading} from "../Loading/Loading";
import Notification from "../Notification/Notification";
import Modal from "../Modal/Modal";
import SignerOperations from "../SignerOperations/SignerOperations";
import ReportsList from "../ReportsList/ReportsList";
import Login from "../Login/Login";
import {
    ANDES_ROUTE,
    FILL_DATA_ROUTE,
    LOGIN_ROUTE,
    OPERATIONS_ROUTE,
    PKI_CERTIFICATES_ROUTE,
    REPORT_LIST_ROUTE, SMS_ROUTE,
    USERS_ROUTE
} from "./routes";
import FillData from "../FillData/FillData";
import PKICertificates from "../PKICertificates/PKICertificates";
import SignerUsers from "../SignerUsers/SignerUsers";
import AndesRequests from "../AndesRequests/AndesRequests";
import SMSOperations from "../SMSOperations/SMSOperations";

const AppContainer = (props) => {


    return (
        <Layout className={'h-100 w-100'}>
            <Modal/>
            <Loading language={props.language}/>
                <Notification/>
                <Layout.Content>
                        <Switch>
                            <Route exact path={LOGIN_ROUTE} component={Login}/>
                            <Route exact path={REPORT_LIST_ROUTE} component={ReportsList}/>
                            <Route exact path={OPERATIONS_ROUTE} component={SignerOperations}/>
                            <Route exact path={USERS_ROUTE} component={SignerUsers}/>
                            <Route exact path={FILL_DATA_ROUTE} component={FillData}/>
                            <Route exact path={PKI_CERTIFICATES_ROUTE} component={PKICertificates}/>
                            <Route exact path={ANDES_ROUTE} component={AndesRequests}/>
                            <Route exact path={SMS_ROUTE} component={SMSOperations}/>
                        </Switch>
                </Layout.Content>
        </Layout>
    );

};
export default withRouter(connect(null, {})(AppContainer));