import {CHANGE_LANGUAGE} from "../types";

const INITIAL_STATE = {
    language: 'es'
};

const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return {...state, ...action.payload.auth};
        default:
            return state;
    }
};
export default languageReducer;